import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import call from "../assets/images/icon-phone.svg"
import mail from "../assets/images/icon-email.svg"
import location from "../assets/images/icon-location.svg"
import website from "../assets/images/icon-website.svg"
import CTABanner from "../components/Common/CTABanner"
import Footer from "../components/App/Footer"
import SEO from "../components/App/SEO"
import ComplaintsForm from "../components/Complaints/ComplaintsForm"
import ContactInfo from "../components/Complaints/AddressInfo"

const Complaints = () => {
  return (
    <Layout>
      <SEO title="Complaints | Oxygen - Home Loans Made Simple" />
      <Navbar />
      <PageBanner
        pageTitle="Complaints"
        pageSubTitle="At Oxygen, we aim to provide the very best service for our customers."
      />
      <div className="container pb-100">
        <div className="row">
          <div className="col-lg-12 col-12 pr-5">
            <div className="complaints-content-area">
              <p className="first-para">
                If you are not satisfied with our service please let us know so
                that we can improve. If you have a concern that we have not been
                able to resolve and you would like to make a complaint you can
                do so through our internal dispute resolution process which is
                designed to deal with complaints genuinely, efficiently and
                effectively.
              </p>
              <h3>How to lodge a complaint</h3>
              <p>
                You can lodge a complaint by completing the form below or
                contact the Complaints Officer by:
              </p>
              <div className="contact-info-area">
                <div className="contact-info-card">
                  <div className="info-text">
                    <img src={call} alt="call icon" />
                    <h5 className="info-label">1300 855 699</h5>
                  </div>
                  <div className="info-text">
                    <img src={mail} alt="mail icon" />
                    <a href="mailto:complaints@oxygen.com.au">
                      complaints@oxygen.com.au
                    </a>
                  </div>
                  <div className="info-text">
                    <img src={location} alt="location icon" />
                    <h5 className="info-label">
                      Complaints Officer,<br></br>Oxygen Home Loans,<br></br>{" "}
                      Level 12,<br></br> 92 Pitt Street<br></br>Sydney NSW
                      2000
                    </h5>
                  </div>
                </div>
              </div>
              <p>
                The Complaints Officer has the necessary experience and
                authority to handle your complaint.
              </p>
              <p>
                Please explain the details of your complaint as clearly as you
                can. If you need assistance explaining your complaint, please
                let the Complaints Officer know, so they can make the necessary
                arrangements.
              </p>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-lg-8 col-12 pr-lg-5">
            <ComplaintsForm />
          </div>
          <div className="col-lg-4 col-12">
            <ContactInfo />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-12 pr-5">
            <div className="complaints-content-area">
              <h3>How we will deal with your complaint</h3>
              <p>
                When we receive a complaint, we will do our best to resolve it
                promptly.
              </p>
              <p>To help us do this we ask that you provide:</p>
              <ol>
                <li>
                  all essential and relevant information, documents, written
                  statements and any other materials that may assist in
                  resolving the complaint; and
                </li>
                <li>
                  any additional information that we may reasonably request.
                </li>
              </ol>
              <p>
                We ask that you provide any information that we request within a
                reasonable time frame.
              </p>
              <h3>What if you are not satisfied?</h3>
              <p>
                If you are not satisfied with our response to your complaint or
                we do not reach agreement within the prescribed timeframe, which
                is 30 days for most complaints and 21 days for complaints
                involving hardship, postponement of enforcement proceedings and
                default notices, you may refer the complaint to our external
                dispute resolution provider, the Australian Financial Complaints
                Authority (AFCA).
              </p>
              <p>AFCA can be contacted by:</p>
              <div className="contact-info-area">
                <div className="contact-info-card">
                  <div className="info-text">
                    <img src={call} alt="call icon" />
                    <h5 className="info-label"> 1800 931 678</h5>
                  </div>
                  <div className="info-text">
                    <img src={mail} alt="mail icon" />
                    <a href="mailto:info@afca.org.au">info@afca.org.au</a>
                  </div>
                  <div className="info-text">
                    <img src={website} alt="website icon" />
                    <a
                      href="https://www.afca.org.au"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.afca.org.au
                    </a>
                  </div>
                </div>
              </div>
              <p>
                This is a free service which provides you with an independent
                mechanism to resolve certain complaints.
              </p>
            </div>
          </div>
        </div>
      </div>

      <CTABanner />
      <Footer />
    </Layout>
  )
}
export default Complaints
